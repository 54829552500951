import React, { useState, useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  Row,
  Accordion,
  useAccordionToggle,
  AccordionContext,
  Card,
} from "react-bootstrap"
import finance from "../images/financials/banner.png"
import fin1 from "../images/fin1.jpg"
import fin2 from "../images/fin2.jpg"
import fin3 from "../images/fin3.jpg"
import finb1 from "../images/finb1.jpg"
import finb2 from "../images/finb2.jpg"
import finb3 from "../images/finb3.jpg"
import close from "../images/close2.png"
import view from "../images/view.png"
import fcrafin201415 from "../pdfs/fcra/fcra-fin-2014-15.pdf"
import fcrafin201516 from "../pdfs/fcra/fcra-fin-2015-16.pdf"
import fcrafin201617 from "../pdfs/fcra/fcra-fin-2016-17.pdf"
import fcrafin201718 from "../pdfs/fcra/fcra-fin-2017-18.pdf"
import fcrafin201819 from "../pdfs/fcra/fcra-fin-2018-19.pdf"
import fcrafin201920 from "../pdfs/fcra/fcra-fin-2019-20.pdf"

import fcra201516q3 from "../pdfs/fcra/fcra-2015-16-q3.pdf"
import fcra201516q4 from "../pdfs/fcra/fcra-2015-16-q4.pdf"

import fcra201617q1 from "../pdfs/fcra/fcra-2016-17-q1.pdf"
import fcra201617q2 from "../pdfs/fcra/fcra-2016-17-q2.pdf"
import fcra201617q3 from "../pdfs/fcra/fcra-2016-17-q3.pdf"
import fcra201617q4 from "../pdfs/fcra/fcra-2016-17-q4.pdf"

import fcra201718q1 from "../pdfs/fcra/fcra-2017-18-q1.pdf"
import fcra201718q2 from "../pdfs/fcra/fcra-2017-18-q2.pdf"
import fcra201718q3 from "../pdfs/fcra/fcra-2017-18-q3.pdf"
import fcra201718q4 from "../pdfs/fcra/fcra-2017-18-q4.pdf"

import fcra201819q1 from "../pdfs/fcra/fcra-2018-19-q1.pdf"
import fcra201819q2 from "../pdfs/fcra/fcra-2018-19-q2.pdf"
import fcra201819q3 from "../pdfs/fcra/fcra-2018-19-q3.pdf"
import fcra201819q4 from "../pdfs/fcra/fcra-2018-19-q4.pdf"

import fcra201920q1 from "../pdfs/fcra/fcra-2019-20-q1.pdf"
import fcra201920q2 from "../pdfs/fcra/fcra-2019-20-q2.pdf"
import fcra201920q3 from "../pdfs/fcra/fcra-2019-20-q3.pdf"
import fcra201920q4 from "../pdfs/fcra/fcra-2019-20-q4.pdf"

import fcra202021q1 from "../pdfs/fcra/fcra-2020-21-q1.pdf"
import fcra202021q2 from "../pdfs/fcra/fcra-2020-21-q2.pdf"
import fcra202021q3 from "../pdfs/fcra/fcra-2020-21-q3.pdf"
import fcra202021q4 from "../pdfs/fcra/fcra-2020-21-q4.pdf"

// ttl
import ttl1 from "../pdfs/ttl-80g-certificate.pdf"
import ttl2 from "../pdfs/ttl-income-tax-certificate.pdf"
import ttl3 from "../pdfs/ttl-pan-card.pdf"
import ttl4 from "../pdfs/ttl-registration-certificate.pdf"
// annual
import ann1 from "../pdfs/annual/annual-report-2011-12.pdf"
import ann2 from "../pdfs/annual/annual-report-2012-13.pdf"
import ann3 from "../pdfs/annual/annual-report-2013-14.pdf"
import ann4 from "../pdfs/annual/annual-report-2014-15.pdf"
import ann5 from "../pdfs/annual/annual-report-2015-16.pdf"
import ann6 from "../pdfs/annual/annual-report-2016-17.pdf"
import ann7 from "../pdfs/annual/annual-report-2017-18.pdf"
import ann8 from "../pdfs/annual/annual-report-2018-19.pdf"
import ann9 from "../pdfs/annual/annual-report-2019-20.pdf"

// eslint-disable-next-line react/prop-types
function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <div
      type="button"
      className={isCurrentEventKey ? "show" : ""}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  )
}
const Financials = () => {
  const fcra = {
    "2014-15": {
      Financials: fcrafin201415,
    },
    "2015-16": {
      "Donations Q3": fcra201516q3,
      "Donations Q4": fcra201516q4,
      Financials: fcrafin201516,
    },
    "2016-17": {
      "Donations Q1": fcra201617q1,
      "Donations Q2": fcra201617q2,
      "Donations Q3": fcra201617q3,
      "Donations Q4": fcra201617q4,
      Financials: fcrafin201617,
    },
    "2017-18": {
      "Donations Q1": fcra201718q1,
      "Donations Q2": fcra201718q2,
      "Donations Q3": fcra201718q3,
      "Donations Q4": fcra201718q4,
      Financials: fcrafin201718,
    },
    "2018-19": {
      "Donations Q1": fcra201819q1,
      "Donations Q2": fcra201819q2,
      "Donations Q3": fcra201819q3,
      "Donations Q4": fcra201819q4,
      Financials: fcrafin201819,
    },
    "2019-20": {
      "Donations Q1": fcra201920q1,
      "Donations Q2": fcra201920q2,
      "Donations Q3": fcra201920q3,
      "Donations Q4": fcra201920q4,
      Financials: fcrafin201920,
    },
    "2020-21": {
      "Donations Q1": fcra202021q1,
      "Donations Q2": fcra202021q2,
      "Donations Q3": fcra202021q3,
      "Donations Q4": fcra202021q4,
    },
  }
  const ttl = {
    "Pan card": {
      "Document Pdf": ttl3,
    },
    "Income Tax Certificate": {
      "Document Pdf": ttl2,
    },
    "80g Certificate": {
      "Document Pdf": ttl1,
    },
    "Registration Certificate": {
      "Document Pdf": ttl4,
    },
  }

  const annual = {
    "2011-12": {
      "Annual Report": ann1,
    },
    "2012-13": {
      "Annual Report": ann2,
    },
    "2013-14": {
      "Annual Report": ann3,
    },
    "2014-15": {
      "Annual Report": ann4,
    },
    "2015-16": {
      "Annual Report": ann5,
    },
    "2016-17": {
      "Annual Report": ann6,
    },
    "2017-18": {
      "Annual Report": ann7,
    },
    "2018-19": {
      "Annual Report": ann8,
    },
    "2019-20": {
      "Annual Report": ann9,
    },
  }

  var yr = {}
  var lnks = {}
  const [finMod, setFinMod] = useState({
    title: "",
    img: "",
    links: {},
    state: "",
  })
  return (
    <Layout pageName="Financials">
      <SEO title="Financials" />
      <Row className="mx-0 z-50">
        <div className="col-lg-6 order-lg-last px-0 jtf-img halfwayBannerColor">
          <img className="w-100" src={finance} />
        </div>
        <div className="col-lg-6 px-0 bg-sec align-center">
          <div className="jtf-cont">
            <div className="text-center text-lg-left">
              <p className="ed-title">Reports and Documents</p>
              <p className="jtf-head mb-lg-3 mb-3">FINANCIALS</p>
              <p className="fi-txt  mb-lg-3 mb-4">
                Teach For India has received its accreditation from Credibility
                Alliance - a consortium of Voluntary Organizations committed
                towards enhancing Accountability and Transparency in the
                Voluntary Sector through good Governance.
              </p>
              <p className="fi-txt  mb-lg-3 mb-4">
                Our Annual Reports below contain an overview of main activities
                across different departments, a review of the progress and
                results achieved in the year; information on our Board members,
                remuneration and reimbursements, and brief financial details.
                Financial statements required as per the FCRA Act, 2013 and
                Rules 2015 are also given below.
              </p>
            </div>
          </div>
        </div>
      </Row>
      <Container className="py-5">
        <Row>
          <div className="fin-card col-lg-4 col-md-6 ">
            <div
              className="bg-sec"
              onClick={() =>
                setFinMod({
                  title: "FCRA Donations",
                  img: finb1,
                  links: fcra,
                  state: "active show",
                })
              }
            >
              <img src={fin1} className="w-100" />
              <p className="fi-txt pb-4 pt-3 px-3">FCRA Donations </p>
            </div>
          </div>
          <div
            className="fin-card col-lg-4 col-md-6 "
            onClick={() =>
              setFinMod({
                title: "Annual Reports",
                img: finb2,
                links: annual,
                state: "active show",
              })
            }
          >
            <div className="bg-sec">
              <img src={fin2} className="w-100" />
              <p className="fi-txt pb-4 pt-3 px-3">Annual Reports</p>
            </div>
          </div>
          <div
            className="fin-card col-lg-4 col-md-6 "
            onClick={() =>
              setFinMod({
                title: "Organisational Documents",
                img: finb3,
                links: ttl,
                state: "active show",
              })
            }
          >
            <div className="bg-sec">
              <img src={fin3} className="w-100" />
              <p className="fi-txt pb-4 pt-3 px-3">Organisational Documents</p>
            </div>
          </div>
        </Row>
      </Container>
      <div className={`model ${finMod.state}`}>
        <div className="model-body finmod">
          <div
            className="modelClose"
            onClick={() =>
              setFinMod({
                title: "",
                img: "",
                links: "",
                state: "",
              })
            }
          >
            <img src={close} />
          </div>
          <Row className="mx-0">
            <div className="col-lg-6 px-0 px-lg-5 mb-3 mb-lg-0">
              <p className="fi-head mb-4 d-block d-lg-none mb-3">
                {finMod.title}
              </p>
              <img src={finMod.img} className="w-100" />
            </div>
            <div className="col-lg-6 px-0 px-lg-5">
              <p className="fi-head mb-4 d-none d-lg-block">{finMod.title}</p>
              <div className="fin-reports">
                <span className="d-none">
                  {(yr = Object.keys(finMod.links))}
                </span>
                <Accordion className="fin-acc mb-5">
                  {yr.map((item, i) => {
                    return (
                      <Card key={i}>
                        <Card.Header>
                          <ContextAwareToggle eventKey={i + 1}>
                            <div className="eleg-title">
                              <p className="ed-title mb-0">{item}</p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={i + 1}>
                          <Card.Body>
                            <span className="d-none">
                              {(lnks = Object.keys(finMod.links[item]))}
                            </span>
                            {lnks.map((quart, k) => {
                              return (
                                <a
                                  key={k}
                                  href={finMod.links[item][quart]}
                                  target="blank"
                                  rel="noreferrer noopener"
                                  className="finLink"
                                >
                                  <span>{quart}</span>
                                  <div>
                                    <img src={view} />
                                  </div>
                                </a>
                              )
                            })}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )
                  })}
                </Accordion>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default Financials
